<template>
  <el-row class="zt-block add-apply">
    <h3>南康工业(家具)设计奖补申请</h3>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="formCheck"
      label-width="240px"
      class="login-form"
    >
      <el-form-item label="企业名称：">
        <template class="flex-1" v-if="ruleForm.info.company_name">{{
          ruleForm.info.company_name
        }}</template>
      </el-form-item>
      <el-form-item prop="info.company_address" label="企业地址：">
        <el-input
          style="max-width: 300px"
          v-model="ruleForm.info.company_address"
          placeholder="请输入企业地址"
        ></el-input>
      </el-form-item>
      <el-form-item prop="info.compRange" label="企业服务范围：">
        <el-input
          style="max-width: 300px"
          v-model="ruleForm.info.compRange"
          placeholder="企业服务范围"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item prop="info.is_in_spec" label="是否为规上企业：">
        <el-radio-group v-model="ruleForm.info.is_in_spec">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item prop="info.legal_name" label="法定代表人姓名：">
        <el-input
          style="max-width: 300px"
          v-model="ruleForm.info.legal_name"
          placeholder="请输入法定代表人姓名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="info.phone" label="联系电话：">
        <el-input
          style="max-width: 300px"
          v-model="ruleForm.info.phone"
          maxlength="11"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="info.companyRealCost"
        label="企业本年度实际研发设计费用："
      >
        <el-input
          oninput="value=value.replace(/[^\d.]/g,'')"
          style="max-width: 300px"
          v-model="ruleForm.info.companyRealCost"
          placeholder="企业本年度实际研发设计费用"
        ></el-input>
        <span class="unit">万元</span>
      </el-form-item>
      <el-form-item prop="info.applyAmount" label="申请奖补金额：">
        <el-input
          oninput="value=value.replace(/[^\d.]/g,'')"
          style="max-width: 300px"
          v-model="ruleForm.info.applyAmount"
          placeholder="申请奖补金额"
        ></el-input>
        <span class="unit">万元</span>
      </el-form-item>
      <el-form-item prop="info.remark" label="企业简介：">
        <el-input
          type="textarea"
          :rows="5"
          maxlength="1000"
          show-word-limit
          v-model="ruleForm.info.remark"
          placeholder="企业简介"
        ></el-input>
      </el-form-item>
      <el-form-item prop="info.businessLicense" label="营业执照：">
        <UploadImgs
          :limit="1"
          v-model="ruleForm.info.businessLicense"
          :prefix="uploadUrl"
        ></UploadImgs>
      </el-form-item>
      <el-form-item prop="info.pros" label="法定代表人身份证：">
        <el-row type="flex">
          <el-col :sm="8" :md="6" :lg="4"
            ><UploadIdcard
              :limit="1"
              uploadText="身份证(国徽面)"
              v-model="ruleForm.info.pros"
              :prefix="uploadUrl"
            ></UploadIdcard
          ></el-col>
          <el-col :sm="12" :md="6" :lg="8">
            <el-form-item prop="info.cons">
              <UploadIdcard
                :showLimit="true"
                :limit="1"
                uploadText="身份证(头像面)"
                v-model="ruleForm.info.cons"
                :prefix="uploadUrl"
              ></UploadIdcard>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        label="设计公司："
        prop="info.designCompany"
        style="margin-bottom: 50px"
      >
        <el-select v-model="ruleForm.info.designCompany" placeholder="请选择">
          <el-option
            v-for="item in designCompany"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="info.designContract" label="设计合同：">
        <UploadComp
          v-model="ruleForm.info.designContract"
          :prefix="uploadUrl"
          uploadAccept="*/pdf"
          :limitLen="200"
          tip="仅支持pdf格式上传"
        ></UploadComp>
      </el-form-item>
      <el-form-item prop="info.designRender" label="渲染图：">
        <UploadComp
          v-model="ruleForm.info.designRender"
          :prefix="uploadUrl"
          uploadAccept="*/pdf,png,jpg"
          :limitLen="200"
          tip="支持pdf、png、jpg 等格式上传"
        ></UploadComp>
      </el-form-item>
      <el-form-item prop="info.designDrawing" label="设计图纸：">
        <UploadComp
          v-model="ruleForm.info.designDrawing"
          :prefix="uploadUrl"
          uploadAccept="*/dwg,pdf,png,jpg"
          :limitLen="200"
          tip="支持dwg、pdf、png、jpg 等格式上传"
        ></UploadComp>
      </el-form-item>
      <el-form-item prop="info.taxInvoice" label="增值税发票：">
        <UploadComp
          v-model="ruleForm.info.taxInvoice"
          :prefix="uploadUrl"
          :limitLen="200"
          tip="支持pdf、png、jpg 等格式上传"
        ></UploadComp>
      </el-form-item>
      <el-form-item prop="info.payProof" label="付款凭证：">
        <UploadComp
          v-model="ruleForm.info.payProof"
          :prefix="uploadUrl"
          :limitLen="200"
          tip="支持pdf、png、jpg 等格式上传"
        ></UploadComp>
      </el-form-item>
      <!-- <el-form-item v-show="!getHistory1">
        我已同意平台授权盖章.同意后,由平台代理企业完成后续流程审批中发起人的签约动作
        <el-button type="primary" size="mini" @click="openDialog"
          >授权</el-button
        >
      </el-form-item> -->
      <el-form-item>
        <el-button
          @click="$router.push('/factory/gov/designAllowance')"
          type="primary"
          >返回</el-button
        >
        <el-button
          v-if="$route.query.type === '13'"
          @click="submitForm(1)"
          type="primary"
          >存草稿</el-button
        >
        <el-button
          v-if="$route.query.type === '1'"
          @click="submitDraft"
          type="primary"
          >提交</el-button
        >
        <el-button
          v-if="$route.query.type === '13'"
          @click="submitForm(2)"
          type="primary"
          >提交</el-button
        >
        <!-- <el-button @click="beforeClose">按钮</el-button>
        getShopNum:{{ getShopNum }} getHistory1: {{ getHistory1 }} -->
      </el-form-item>
    </el-form>
    <el-dialog
      @close="refresh"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <div v-if="popTitle == '签章'">
        <Sign
          :popTitle="popTitle"
          @data="refresh"
          @signState="signState"
          @hide="hide"
        ></Sign>
      </div>
      <div v-if="popTitle == '自动签约'">
        <Sign
          :popTitle="popTitle"
          @data="refreshAuto"
          @signState="signState"
          @hide="hide"
        ></Sign>
      </div>
      <div v-if="popTitle == '遇到问题'">
        <SignBack :popTitle="popTitle" @hide="popVisible = false"></SignBack>
      </div>
    </el-dialog>
    <el-dialog
      class="cus-dialog"
      :visible.sync="dialogVisible"
      :before-close="beforeClose"
      title="授权"
    >
      <!-- <el-button @click="test">测试</el-button> -->
      <iframe :src="htUrl" frameborder="0" width="100%" height="100%"></iframe>
    </el-dialog>
  </el-row>
</template>
<script>
import { applyAllowanceSave, applyAllowanceDraftSave } from "@/service/factory";
import { contractAddr, contractAutoAddr } from "@/service/contract";
// import { designCompany } from "@/utils/constant";
import { getListDesign } from "@/service/company";
import { Loading } from "element-ui";
import Sign from "@/components/sign/sign.vue";
import SignBack from "@/components/sign/signBack.vue";
import { mapGetters } from "vuex";
import { getCompExt } from "@/service/contract";
import store from "@/store";
export default {
  components: {
    Sign,
    SignBack,
  },
  props: {
    initUpdate: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["getShopNum", "getHistory"]),
    uploadUrl() {
      return `gov/sjjb/${this.$store.state.loginUser.comp.id}`;
    },
    getHistory1() {
      if (this.getHistory) {
        console.log("getHistory",this.getHistory);
        return this.getHistory.isActive
      }
      return this.getHistory
    }
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    const checkAuth = (rule, value, callback) => {
      if (!this.getHistory1) {
        callback();
        return false;
      } else {
        console.log("checkauth");
        callback();
      }
    };
    var update = this.initUpdate
      ? this.initUpdate
      : {
          info: {},
        };
    return {
      rules: {
        [`info.phone`]: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        [`info.legal_name`]: [
          { required: true, message: "请输入法定代表人姓名", trigger: "blur" },
        ],
        [`info.is_in_spec`]: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        [`info.company_address`]: [
          { required: true, message: "请输入企业地址", trigger: "blur" },
        ],
        [`info.compRange`]: [
          { required: true, message: "请输入企业服务范围", trigger: "blur" },
        ],
        [`info.designCompany`]: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        [`info.companyRealCost`]: [
          {
            required: true,
            message: "请输入企业实际研发费用",
            trigger: "blur",
          },
        ],
        [`info.applyAmount`]: [
          { required: true, message: "请输入企业申请金额", trigger: "blur" },
        ],
        [`info.remark`]: [
          { required: true, message: "请输入公司简介", trigger: "blur" },
        ],
        [`info.businessLicense`]: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        [`info.pros`]: [
          { required: true, message: "请上传身份证的正面", trigger: "blur" },
        ],
        [`info.cons`]: [
          { required: true, message: "请上传身份证的反面", trigger: "blur" },
        ],
        [`info.designContract`]: [
          { required: true, message: "请上传设计合同", trigger: "blur" },
        ],
        [`info.designRender`]: [
          { required: true, message: "请上传渲染图", trigger: "blur" },
        ],
        [`info.designDrawing`]: [
          { required: true, message: "请上传设计图纸", trigger: "blur" },
        ],
        [`info.taxInvoice`]: [
          { required: true, message: "请上传增值税发票", trigger: "blur" },
        ],
        [`info.payProof`]: [
          { required: true, message: "请上传付款凭证或截图", trigger: "blur" },
        ],
        [`info.isAuth`]: [
          { required: true, validator: checkAuth, trigger: "blur" },
        ],
      },
      ruleForm: update,
      designCompany: [], // 已认证的设计公司
      popVisible: false,
      popTitle: null,
      loading1: false,
      loading2: false,
      draftLoading: false,
      dialogVisible: false,
      htUrl: "",
    };
  },
  mounted() {
    this.getDesignComps();
    this.getAutoAuth();
  },
  methods: {
    async getDesignComps() {
      let arr = [];
      await getListDesign().then((res) => {
        if (res && res.list.length>0) {
          res.list.forEach(e => {
            if (e.cert_stat == 2) {
              arr.push(e);
            }
          });
          this.designCompany = arr;
        }
      })
    },
    getAutoAuth() {
      getCompExt()
        .then((rst) => {
          const loginUser = this.$store.state.loginUser;
          if (rst && rst.auto_sign_stat === 2) {
            store.dispatch("setHistory1", {...loginUser,isActive: true});
          } else {
            store.dispatch("setHistory1", {...loginUser,isActive: false});
          }
        })
        .catch((e) => {
          // reject(e);
        });
    },
    async beforeClose() {
      await this.getAutoAuth();
      this.$forceUpdate();
      this.dialogVisible = false;
    },
    submitDraft() {
      this.$refs["formCheck"].validate((valid) => {
        if (valid) {
          this.draftLoading = true;
          try {
            applyAllowanceDraftSave(this.$route.params.id).then((rst) => {
              this.$message.success("提交成功");
              this.goBack();
            });
          } catch (e) {
            this.draftLoading = false;
          }
        }
      });
    },
    submitForm(type) {
      this.$refs["formCheck"].validate((valid) => {
        if (valid) {
          this.submit(type);
        } else {
          console.log("submitForm", valid);
        }
      });
    },
    paramsHandler() {
      const {
        legal_name,
        phone,
        company_name,
        company_address,
        compRange,
        applyAmount,
        companyRealCost,
        remark,
        designCompany,
        businessLicense,
        pros,
        cons,
        designContract,
        designRender,
        designDrawing,
        taxInvoice,
        payProof,
        is_in_spec,
      } = this.ruleForm.info;
      return {
        compName: company_name,
        compAddr: company_address,
        compRange,
        legalName: legal_name,
        legalTel: phone,
        fee: companyRealCost,
        applyAmount,
        compIntro: remark,
        desginCompId: designCompany,
        desginCompName: this.designCompany.find((v) => v.id === designCompany)
          .name,
        attachment: {
          license: businessLicense,
          idCard: {
            pros,
            cons,
          },
          contracts: designContract,
          renders: designRender,
          drawings: designDrawing,
          receipts: taxInvoice,
          vouchers: payProof,
        },
        is_in_spec,
      };
    },
    submit(type) {
      this[`loading${type}`] = true;
      try {
        const params = this.paramsHandler();
        const parameter =
          type === 2
            ? {
                ...params,
                submitNow: true,
              }
            : { ...params, submitNow: false };
        this.designApplySave(parameter);
      } catch (e) {
        this[`loading${type}`] = false;
      }
    },
    goBack() {
      this.$router.replace("/factory/gov/designAllowance");
    },
    designApplySave(params) {
      applyAllowanceSave(params)
        .then((rst) => {
          if (params.submitNow) {
            this.$message.success("提交成功");
          }
          this.goBack();
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    refresh() {
      this.loading1 = false;
      this.draftLoading = false;
      this.goBack();
    },
    refreshAuto() {
      this.loading1 = false;
      this.draftLoading = false;
      // this.goBack();
    },
    openDialog() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.contractAutoAddr();
      });
    },
    contractAutoAddr() {
      contractAutoAddr().then((rst) => {
        console.log(rst);
        this.htUrl = rst.url;
        // window.open(rst.url);
        // this.signState("自动签约");
      });
    },
    goSign(row) {
      if (this.$store.state.loginUser.comp.cert_stat != 2) {
        this.$message.error("企业未认证");
        this.refresh();
        return;
      } else {
        this.$confirm("确认是否发起签章?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        })
          .then(() => {
            this.going(row.contract_no);
          })
          .catch((e) => {
            this.$message.info("取消");
            // this.refresh();
            this.$router.push({ path: "/factory/gov/designAllowance" });
          });
      }
    },
    going(contract_no, user_id) {
      this.signLoading = Loading.service({
        fullscreen: true,
        text: "拼命加载中....",
      });
      contractAddr(contract_no, user_id)
        .then((rst) => {
          console.log("contractAddr", rst);
          if (rst && rst.url) {
            this.sign(rst.url);
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
          this.signLoading.close();
        });
    },
    sign(url) {
      this.signLoading.close();
      if (url) window.open(url);
      this.signState("签章");
    },
    hide() {
      this.popVisible = false;
      this.$message.success("状态已更新");
    },
    signState(text) {
      this.popTitle = text;
      this.popVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.add-apply {
  padding: 20px;
}
.cus-dialog {
  /deep/.el-dialog {
    width: 1200px;
    height: 1000px;

    .el-dialog__body {
      padding: 0;
      height: 100%;
    }
  }
}
</style>
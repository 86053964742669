<template>
  <el-row>
    <el-col v-if="editing">
      <Add-Apply v-if="ruleForm" :initUpdate="ruleForm"></Add-Apply>
    </el-col>
    <el-col v-else>
      <Apply-Table
        v-if="applyInfo"
        :applyInfo="applyInfo"
        :loading="loading"
      ></Apply-Table>
      <el-row
        type="flex"
        justify="center"
        style="margin-top: 20px"
        v-if="applyInfo"
      >
        <el-button :disabled="canDownload" @click="singleAffixDownload"
          >申请表下载</el-button
        >
      </el-row>
      <Affix v-if="applyInfo" :affix="applyInfo"></Affix>
      <Approve-Process :approveProcessInfo="applyInfo"></Approve-Process>
    </el-col>
  </el-row>
</template>

<script>
import ApplyTable from "@/components/approve/ApplyTable";
import Affix from "@/components/approve/Affix";
import ApproveProcess from "@/components/approve/ApproveProcess";
import AddApply from "@/components/approve/AddApply";
import { getApplyAllowanceId, setApplyAllowanceFlow } from "@/service/factory";
export default {
  components: {
    ApplyTable,
    Affix,
    ApproveProcess,
    AddApply,
  },
  computed: {
    editing() {
      // 1草稿状态 13 提交新申请(提交新申请状态3 与审核不通过状态 3 冲突,导致审核不通过查看申请信息变成提交状态)
      return ["1", "13"].includes(this.$route.query.type);
    },
    canDownload() {
      // 设计中心
      return !(
        this.applyInfo &&
        this.applyInfo.approves.find((item) => item.code === "2501") &&
        this.applyInfo.approves.find((item) => item.code === "2501").signedAt
      );
    },
  },
  data() {
    return {
      ruleForm: null,
      applyInfo: null,
      loading: false,
    };
  },
  async created() {
    this.comp = this.$store.state.loginUser.comp;
    const id = this.$route.params.id;
    const type = this.$route.query.type;

    if (type === "13") {
      this.ruleForm = {
        info: {
          company_name: this.comp.name,
          legal_name: this.comp.legal_name,
          company_address: this.comp.addr,
          phone: this.comp.legal_mobile,
          remark: "",
          is_in_spec: this.comp.is_in_spec,
        },
      };
    } else {
      try {
        const data = await this.getApplyAllowanceDetail(id);
        if (type === "1") {
          this.ruleForm = {
            info: {
              company_name: data.compName,
              company_address: data.compAddr,
              compRange: data.compRange,
              companyRealCost: data.fee,
              applyAmount: data.applyAmount,
              legal_name: data.legalName,
              phone: data.legalTel,
              remark: data.compIntro,
              businessLicense: data.attachment.license,
              pros: data.attachment.idCard.pros,
              cons: data.attachment.idCard.cons,
              designCompany: data.desginCompId,
              designContract: data.attachment.contracts,
              designRender: data.attachment.renders,
              designDrawing: data.attachment.drawings,
              taxInvoice: data.attachment.receipts,
              payProof: data.attachment.vouchers,
              id,
              is_in_spec: this.$store.state.loginUser.comp.is_in_spec,
            },
          };
        } else {
          this.applyInfo = data;
          const result1 = data.approves.find((item) => item.code === "2501");
          this.applyInfo.result1 = (result1 && result1.desc) || "";
          const result2 = data.approves.find((item) => item.code === "2503");
          this.applyInfo.result2 = (result2 && result2.desc) || "";
          this.applyInfo.is_in_spec = this.$store.state.loginUser.comp.is_in_spec;
        }
      } catch (e) {
        console.error(e.message);
      }
    }
  },
  methods: {
    singleAffixDownload() {
      const { compName, approves, id } = this.applyInfo;
      this.$confirm("下载为当前预览格式,确认下载?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          // 设计中心或者升规办不通过 a3 就不存在,只会有一份文件
          let a1 = approves.find((item) => item.code === "2501");
          let a3 = approves.find((item) => item.code === "2503");
          const fileData = [
            {
              folderName: "申请表",
              fileData: !a3
                ? [a1.contract_no]
                : [a1.contract_no, a3.contract_no],
            },
          ];
          this.exportZip(fileData, `${compName}${id}申报材料`);
        })
        .catch((e) => {
          console.error(e.message);
          this.$message.info("取消");
        });
    },
    // 单条申请记录导出申报材料
    exportZip(fileData, fileName) {
      import("@/vendor/Export2Zip")
        .then((excel) => {
          excel.export_file_to_zip1(
            fileData,
            `${fileName}${this.$moment().format("YYYYMMDDHHmmss")}`
          );
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
    goBack() {
      const type = this.$route.query.type;
      if (type == 7) {
        this.$router.push("/factory/gov/designAllowance");
      } else {
        this.$router.push("/approve/allowance");
      }
    },
    getApplyAllowanceDetail(id) {
      return new Promise((resolve) => {
        getApplyAllowanceId(id)
          .then((rst) => {
            if (rst) {
              resolve(rst);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.message);
          });
      });
    },
    approveSubmit(data) {
      let detail = {};
      if (data.itemId) {
        detail.item = data.itemId;
      }
      if (data.applyAmount) {
        detail.amount = data.applyAmount;
      }
      console.log("detail", {
        flowId: this.$route.params.id,
        state: data.isApproved ? 1 : 2,
        cause: data.reason,
        desc: data.remark,
        detail,
      });
      setApplyAllowanceFlow({
        flowId: this.$route.params.id,
        state: data.isApproved ? 1 : 2,
        cause: data.reason,
        desc: data.remark,
        detail,
      })
        .then((rst) => {
          // todo
          this.$message.success("审批完成!");
          this.$router.push("/approve/allowance");
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.back {
  padding-left: 20px;
}
</style>